
@media all and (max-aspect-ratio: 1200 / 1000) {

    .grid-container {
        grid-template-rows: repeat(7, 14%) 2%;
        grid-template-columns: 50% 50%;
        margin: 0 !important;
        gap: 170px 0px;
        overflow-y: scroll !important;
    } 
    

  
    


    
    
    #title-image {
        grid-column: 1 / 3 !important;
        margin: 0px auto -10px auto !important;
        order: 0;
    }
    #title-image img {
        transform: translateY(35%);
    }

    #freetime {
        grid-column: 1 / 1 !important;
        order: 1;
    }
    #freetime img {
        transform: translateY(0) !important;
    }
    
    #phone {
        grid-column: 2 / 2 !important;
        order: 2;
    }
    #phone img {
        transform: rotate(40deg) translateX(0) translateY(-3%) !important;
    }
    
    

    #question {  
        grid-column: 1 / 1 !important;
        order: 3;
    } 

    #question img {
        transform: translateX(-10%) translateY(0) !important;
    }

    
    #pizza {
        grid-column: 2 / 2 !important;
        order: 4;
    }
    #pizza img { 
        transform: translateY(8%) translateX(-20%) !important;
    }
    

    #party {
        grid-column: 1 / 1 !important;
        order: 5;
    }
    #party img { 
        width: 70% !important;
        transform: translateY(15px) translateX(40px) !important;
    }
    

    
    #pigeon {
        grid-column: 2 / 2 !important;
        order: 6;
    }
    #pigeon img { 
        width: 67%;
        transform:  rotate(-15deg) translateY(0) translateX(0) !important;
    }
    


    
    #nectarine {
        grid-column: 1 / 1 !important;
        order: 7;
    }

    #nectarine img {
        transform: rotate(12deg) translateY(10%) !important;
    }

    #buddha {
        grid-column: 2 / 2 !important;
        order: 8;
    }
    #buddha img {
        transform: translateY(0) translateX(0) !important;
        width: 50% !important;
    }
   
    
    #shampoo {
        grid-column: 1 / 1 !important;
        order: 9;
    }
    #shampoo img {
        transform: translateX(0) translateY(0) !important;
    }

    

    #blueman {
        grid-column: 2 / 2 !important;
        order: 10;
    }
    #blueman img {
        width: 60% !important;
        transform: translateX(-30px) translateY(20px) !important;
    }
    
    
    #giveup {
        grid-column: 1 / 3 !important;
        order: 11;
    }
    #giveup img {
        width: 35% !important;
    }
    .page-container {
        padding: 3% 10% !important;
    }
    .page-container p {
        font-size: 1.1rem !important;
    }
    .page-container li {
        font-size: 1rem !important;
    
    }
    .close-button {
        width: 5% !important;
        right: 15px !important;
    }
 
    #interview-selectors button, #interview-selectors button:active, #interview-selectors button:focus {
        background-color: none;
        background: none;
        font-size: 175% !important;
        
        transition: color 1s ease-out, transform 0.5s ease-out;
        margin: 0px 5px;
    }
    .close-button-video {
        width: 5% !important;
        height: 5% !important;
    }

}
@media all and (max-aspect-ratio: 600 / 950) {
    .grid-container {
        gap: 17px 0px;
        overflow-y: scroll !important;
    }

  
   
    
    .close-button{
        width: 7% !important;
        right: 15px !important;
    }
    .close-button-video {
        /* transform: scale(150%) !important; */
        width: 7% !important;
        height: 7% !important;
    }
    
    .page-container p {
        font-size: .9rem !important;
    }
    .page-container li {
        font-size: .9rem !important;
    
    }
    ::-webkit-scrollbar {
        width: .2em !important;
    }

  
}

@media all and (max-width: 1000px) {
    #poster {
        background-image: url('./img/posterLarge.jpg');
    }
}

@media all and (max-width: 768px) {
    #poster {
        background-image: url('./img/posterMed.jpg');
    }
}

@media all and (max-width: 500px) {
    #poster {
        background-image: url('./img/posterSmall.jpg');
    }
}

/* for wide horizontal */
@media all and (max-height: 450px) {
    .page-container {
        padding: 3% 10% !important;
    }
    .page-container p {
        font-size: .9rem !important;
    }
    .page-container li {
        font-size: .9rem !important;
    }
    /* .grid-container {
        overflow-y: scroll !important;
    } */
    #page-background {
        overflow-y: scroll !important;
    }
    ::-webkit-scrollbar {
        width: .2em !important;
    }
    

}