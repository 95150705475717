@import url('https://fonts.googleapis.com/css2?family=Gochi+Hand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Londrina+Outline&display=swap');
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: 'Gochi Hand';
  font-size: 20px;
}

button {
  display: inline;
  border: none;
  margin: 0;
  text-decoration: none;
  background: none;
  font-family: sans-serif;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}



button:focus {
  outline: none;
  outline-offset: none;
}

button:active {
  background: none;
  background-color: none;
  border: none;
}


:root {
  --color-gold: #af9f82;
  --color-highlight: #352507;
  --color-gold-hover: #ece1cd;
  --default-transition: all 0.5s ease-in-out;
  --color-grey-1: #ebe7dc;
  --color-gold-dark-1: #746a57;
  --color-gold-dark-2: #3d382e;
}

::selection {
  background: var(--color-highlight);
  border-radius: 50%;
  color: var(--color-grey-1);
  transition: var(--default-transition);
}

p {
  transition: var(--default-transition);
}

/* Remove default margin and padding */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
}

/* Remove list styles (bullets/numbers) */
ol,
ul {
  list-style: none;
}

/* Remove hyperlink styles */
a {
  text-decoration: none;
  color: inherit;
}

/* Set base font size and line height */
body {
  font-family: Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
}

/* Remove the outline for focusable elements */
button,
input,
select,
textarea {
  outline: none;
}

.App {
  overflow-x: hidden !important;
}

#page-background {
  height: 100vh;
  width: 100vw;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  background-color: black;
}

.grid-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 28% 35% 37%;
  grid-template-columns: repeat(20, 5%);
  min-height: 450px; 
  max-height: 1500px;
  overflow-y: hidden;
  overflow-x: hidden;
  /* background-color: navy; */
}


.animate-out {
  animation: image-out 2.5s ease-in-out 0s 1 forwards;
  pointer-events: none;

}

.animate-in {
  opacity: 0;
  animation: image-in 2.5s ease-in-out 1s 1 forwards;
  pointer-events: all;

}

::-webkit-scrollbar {
  width: .5em;
}

::-webkit-scrollbar-track {
  background: var(--color-black);
  border-radius: 0;
  margin-block: .5em;

}

::-webkit-scrollbar-thumb {
  background: var(--color-gold-dark-1);
  border-radius: 100vw;
  /* box-shadow: inset 0px 0px 0px 5px #000000, 0px 0px 0px 5px #000000; */
  border: 5px solid var(--color-black);
}

::-webkit-scroll-thumb:hover {
  background-color: var(--color-gold-hover);
}

@keyframes image-out {
  0% {
    transform: scale(100%);
    opacity: 1;
    pointer-events: all;
  }
  100% {
    transform: scale(75%);
    opacity: 0;
    pointer-events: none;
  }
}

@keyframes image-in {
  0% {
    transform: scale(75%);
    opacity: 0;
    
  }
  100% {
    transform: scale(100%);
    opacity: 1;
  }
}

@keyframes fade-in-video {
  0% {opacity: 0%;}
  100% {opacity: 100%;}
}

@keyframes fade-out-video {
  0% {opacity: 100%;}
  100% {opacity: 0%;}
}