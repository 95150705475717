.image-container { 
    position: relative;
    transition: filter .5s ease-in-out;
    cursor: pointer;
    /* margin: auto; */
    /* background-color: rgba(255, 251, 0, 0.486); */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }


.image-container:hover {
    filter: brightness(150%);
    

}
#title-image {
    /* margin: auto; */
    grid-column: 5 / 17;
    width: 90%;
    animation: wobble 2s ease-in-out infinite alternate;

}

#title-image img {
    width: 100%;
}

#title-image:hover {
    animation-play-state: paused;
    cursor: pointer;
}

@keyframes wobble {
    0% {
      transform: rotate(0) translateY(0px);
    }
    20% {
      transform: rotate(-.2deg);
    }
    40% {
        transform: rotate(.2deg) translateY(-1px);
    }
    60% {
        transform: rotate(-.2deg);
      }
    80% {
        transform: rotate(.2deg) translateY(.5px);
    }
    100% {
        transform: rotate(-.2deg);
      }
   
    
}


#phone {
    position: relative;
    grid-column: 17 / 21;
    /* top: 7vw;
    right: 1vw; */
    animation: phone-inactive 2s linear infinite alternate;
}

#phone:hover {
    animation-play-state: paused;
}

@keyframes phone-inactive {
    0% {
        transform: translateY(0px);
    }
    100% {
        transform: translateY(5px);
    }
}

#phone img {
    transform: rotate(40deg) translateX(-10%) translateY(35%);

    width: 65%;
}


#freetime {
    position: relative;
    grid-column: 1 / span 4;
    animation: jitterAnimation 8s linear infinite alternate;
}


@keyframes jitterAnimation {
    0%, 100% {
      transform: translate(0, 0);
    }
    25% {
      transform: translate(1px, -1px);
    }
    50% {
      transform: translate(-1.5px, .5px);
    }
    75% {
      transform: translate(.5px, 1.5px);
    }
  }



#freetime:hover {
    animation-play-state: paused;
}

#freetime img {
    width: 75%;
    transform: translateY(10%);
}

#question {  
    width: 100%;
    grid-column: 1 / 5;
    animation: stretchAnimation 11s infinite cubic-bezier(0.25, 0.1, 0.25, 1);
    animation-delay: 1s, 2s, 3s; /* Adjust these values for irregular intervals */
} 

#question img {
    width: 80%;
    transform: translateX(25%) rotate(-0deg);
}

@keyframes stretchAnimation {
    0%, 100% {
      transform: scaleY(100%) scaleX(100%);
    }
    35% {
      transform: scaleY(109%) scaleX(95%);
    }
  }


#pizza {
    grid-column: 5 / 9;
    position: relative;
    /* right: 3vw;
    bottom: 7vw; */
    animation: pizza-inactive 5s ease-in-out infinite alternate;
}

#pizza:hover {
    animation-play-state: paused;
}

#pizza img { 
    width: 75%;
    transform: translateY(-30%);
}
@keyframes pizza-inactive {
    0% {
        transform: rotate3d(5, 1, 1, 0deg);
    }
    100% {
        transform: rotate3d(5, 1, 1, 30deg);
    }
}

#party {
    position: relative;
    transform: rotate(5deg);
    grid-column: 9 / 13;
    animation: jitterAnimation 5s infinite;

}

#party:hover {
    animation-play-state: paused;
}

#party img { 
    width: 130%;
    transform: translateY(10%);
}

#pigeon {
    position: relative;
    grid-column: 13 / 17;
    animation: pigeon-inactive 16s ease-in infinite forwards;

}

#pigeon:hover {
    animation-play-state: paused;
}

@keyframes pigeon-inactive {
    0%, 100% {
        transform: rotate(0deg) translateY(0px);;
    }
    30% {
        transform: rotate(20deg) translateY(0px);;
    }
    50% {
        transform: rotate(20deg) translateY(0px);;
    }
    51% {
        transform: rotate(20deg) translateY(-5px);
    }
    52% {
        transform: rotate(20deg) translateY(0px);
    }


    53% {
        transform: rotate(20deg) translateY(0px);;
    }
    54% {
        transform: rotate(20deg) translateY(-5px);
    }
    55% {
        transform: rotate(20deg) translateY(0px);
    }
    
    70% {
        transform: rotate(20deg) translateY(0px);
    }

}

#pigeon img { 
    width: 67%;
    transform:  rotate(-15deg) translateY(-30%) translateX(5%);
}

#nectarine {
    position: relative;
    transform: rotate(10deg);
    grid-column: 17 / 21;
    /* left: 8vw;
    top: 1vw; */
    animation: nectarine-inactive 3s ease-in infinite alternate;
}

@keyframes nectarine-inactive {
    0% {
        transform: skew(0deg, 0deg);
    }
    100% {
        transform: skewX(5deg);
    }
}

#nectarine:hover {
    animation-play-state: paused;
}

#nectarine img { 
    width: 80%;
    transform: rotate(12deg);
}

#buddha {
    position: relative;
    grid-column: 1 / 6;
    animation: buddha-inactive 7s ease-out infinite forwards;
}
#buddha:hover {
    animation-play-state: paused;
}
#buddha img {
    width: 60%;
    transform: translateY(-35%) translateX(-25%);
}
@keyframes buddha-inactive {
    0% {
        transform: rotate(0deg);
    }
    5% {
        transform: rotate(0deg);
    }
    8% {
        transform: rotate(3deg);
    }
    14% {
        transform: rotate(-3deg);
    }
    20% {
        transform: rotate(3deg);
    }
    23% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(0deg);
    }
    
}

#shampoo {
    position: relative;
    grid-column: 6 / 11;
    /* left: 2vw; */
    animation: shampoo-inactive 7s ease-in infinite forwards;
    transform-origin: center;

}

#shampoo:hover {
    animation-play-state: paused;
}
#shampoo img {
    width: 47%;
    transform: translateX(-45%) translateY(-20%);
}

@keyframes shampoo-inactive {
    0% {
        transform: scale(100%, 100%) translate(0px, 0px);
    }
    50% {
        transform: scale(100%, 100%) translate(0px, 0px);
    }
    51% {
        transform: scale(95%, 110%) translate(-1px, -4px);
    }
    52% {
        transform: scale(100%, 100%) translate(-1px, -4px);
    }
    53% {
        transform: scale(95%, 110%) translate(-3px, -8px);
    }
    56% {
        transform: scale(100%, 100%) translate(-3px, -8px);
    }
    85% {
        transform: scale(100%, 100%) translate(-3px, -8px);
    }
    100% {
        transform: scale(100%, 100%) translate(0px, 0px);

    }
}

#blueman {
    position: relative;
    grid-column: 11 / 16;
    animation: bounceAnimation 5s infinite 3s ease-in-out;


}
#blueman:hover {
    animation-play-state: paused;
}
#blueman img {
    width: 65%;
    transform: translateY(-10%) translateX(10%);
}

@keyframes bounceAnimation {
    0%, 30%, 100% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }
  }

.eyes {
    position: absolute;
    width: 21% !important;
    display: none;
}

#eye1 {
    top: 23%;
    left: 21%;
    transform: rotate(13deg);
    animation: eye1 5s linear infinite forwards;
}

@keyframes eye1 {
    0% {
        opacity: 1;
    }
    40% {
        opacity: 1;
    }
    41% {
        opacity: .4;
    }
    42% {
        opacity: 1;
    }
    45% {
        opacity: 1;
    }
    46% {
        opacity: .4;
    }
    47% {
        opacity: 1;
    }
}

#eye2 {
    top: 41%;
    left: 60%;
    transform: rotate(-13deg);
    animation: eye2 4.5s linear infinite forwards;
}


@keyframes eye2 {
    0% {
        opacity: 1;
    }
    75% {
        opacity: 1;
    }
    76% {
        opacity: .4;
    }
    77% {
        opacity: 1;
    }
}


#giveup {
    position: relative;
    grid-column: 16 / 21;
    animation: giveup-scale 6s linear infinite forwards;
}
#giveup:hover {
    animation-play-state: paused;
    filter: brightness(150%);

}
#giveup img {
    width: 50%;
 
}

@keyframes giveup-inactive {
    0% {
        filter: brightness(100%);
    }
    30% {
        filter: brightness(100%);
    }
    31% {
        filter: brightness(150%);
    }
    32% {
        filter: brightness(100%);
    }
    33% {
        filter: brightness(100%);
    }
    34% {
        filter: brightness(150%);
    }
    35% {
        filter: brightness(100%);
    }
    65% {
        filter: brightness(100%);
    }
    66% {
        filter: brightness(150%);
    }
    67% {
        filter: brightness(100%);
    }
    95% {
        filter: brightness(100%);
    }
    96% {
        filter: brightness(150%);
    }
    97% {
        filter: brightness(100%);
    }
    100% {
        filter: brightness(100%);
    }

}

@keyframes giveup-scale {
    0% {
        transform: scale(100%);
    }
    30% {
        transform: scale(100%);
    }
    31% {
        transform: scale(103%);
    }
    32% {
        transform: scale(100%);
    }
    33% {
        transform: scale(100%);
    }
    34% {
        transform: scale(103%);
    }
    35% {
        transform: scale(100%);
    }
    65% {
        transform: scale(100%);
    }
    66% {
        transform: scale(103%);
    }
    67% {
        transform: scale(100%);
    }
    95% {
        transform: scale(100%);
    }
    96% {
        transform: scale(103%);
    }
    97% {
        transform: scale(100%);
    }
    100% {
        transform: scale(100%);
    }
}





@keyframes blur-wobble {
    0%, 100% {
        filter: blur(0px);
    }
    30% {
        filter: blur(3px);
    }
    50% {
        filter: blur(0px);
    }
    60% {
        filter: blur(0px);
    }
    65% {
        filter: blur(4px);
    }
    62% {
        filter: blur(2px);
    }
    75% {
        filter: blur(2px);
    }
    80% {
        filter: blur(4px);
    }
    85% {
        filter: blur(0px);
    }


}