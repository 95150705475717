.video-container {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    margin: 0;
    backdrop-filter: blur(100px);
    display: flex;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    /* transition: opacity 2s; */
    /* transition-delay: 1s; */
}

.video-container iframe {
    width: 120%;
    height: 100%;
    margin-inline: 3%;
    /* margin: -20px 0px 0px -20px; */
}

.video-visible {
    /* opacity: 1 !important; */
    animation: fade-in-video 1s ease-in-out 2s 1 forwards;
}

.video-invisible {
    animation: fade-out-video 1s ease-in-out 0s 1 forwards;
}

.close-button-video {
    right: 10px;
    fill: var(--color-gold);
    position: fixed;
    width: 2.5%;
    top: 10px;
    transition: var(--default-transition);
}

.close-button-video:hover {
    fill: var(--color-gold-hover);
    cursor: pointer;
}



