.page-container {
    background-color: black;
    color: var(--color-gold);
    width: 100%;
    height: 100%;
    padding: 3% 25%;
    max-height: fit-content;
    position: absolute;
    overflow-x: hidden;
    opacity: 0;

}

.page-container p {
    font-size: 1.2rem;
    text-align: justify;
    text-justify: inter-word;
    margin: 25px 0px;
}

.page-container ul {
    text-align: center;
    margin-top: 25px;
}

.page-container li {
    font-size: 1.2rem;

}

.close-button {
    fill: var(--color-gold);
    position: fixed;
    width: 2.5%;
    top: 10px;
    right: 30px;
    transition: var(--default-transition);

}

.close-button:hover {
    fill: var(--color-gold-hover);
    cursor: pointer;
}

.back-to-top {
    fill: var(--color-gold);
    position: fixed;
    height: 17%;
    bottom: 30px;
    right: 10%;
    transition: var(--default-transition);
}

.back-to-top:hover {
    cursor: pointer;
    fill: var(--color-gold-hover);
}

#poster {
    background-image: url('../../img/posterFull.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 0;
    padding-top: 141.4%;
}